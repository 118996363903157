var Common = Common || {};

// TODO default style?
Common.Button = React.createClass({
  propTypes: {
    label: React.PropTypes.string.isRequired,
    onClick: React.PropTypes.func.isRequired,
    style: React.PropTypes.object,
    disabled: React.PropTypes.bool,
    disabled_style: React.PropTypes.object,
    selected: React.PropTypes.bool,
    selected_style: React.PropTypes.object,
    hover_style: React.PropTypes.object
  },
  getDefaultProps: function () {
    return {
      disabled_style: {cursor: 'default', opacity: 0.5},
      hover_style: {cursor: 'pointer'}
    };
  },
  getInitialState: function () {
    return {hover: false};
  },
  onMouseOver: function () {
    this.setState({hover: true});
  },
  onMouseOut: function () {
    this.setState({hover: false});
  },
  render: function () {
    var on_click = this.props.onClick;
    var computed_style = _.extend({}, this.props.style);

    if (this.props.disabled) {
      computed_style = _.extend(computed_style, this.props.disabled_style);
      on_click = null;
    } else if (this.props.selected) {
      computed_style = _.extend(computed_style, this.props.selected_style);
      on_click = null;
    } else if (this.state.hover) {
      computed_style = _.extend(computed_style, this.props.hover_style);
    }
    return (
        <div
            onClick={on_click}
            style={computed_style}
            onMouseOver={this.onMouseOver}
            onMouseOut={this.onMouseOut}
        >
          {this.props.label}
        </div>
    );
  }
});

Common.CloseButton = React.createClass({
  propTypes: {
    onClick: React.PropTypes.func.isRequired,
    label: React.PropTypes.string,
    style: React.PropTypes.object,
    disabled: React.PropTypes.bool,
    disabled_style: React.PropTypes.object,
    selected: React.PropTypes.bool,
    selected_style: React.PropTypes.object,
    hover_style: React.PropTypes.object
  },
  getDefaultProps: function () {
    return {label: 'x'};
  },
  render: function () {
    return (
        <Common.Button
            label={this.props.label}
            onClick={this.props.onClick}
            style={this.props.style}
            disabled={this.props.disabled}
            disabled_style={this.props.disabled_style}
            selected={this.props.selected}
            selected_style={this.props.selected_style}
            hover_style={this.props.hover_style}
        />
    );
  }
});

Common.ColorSwatch = React.createClass({

  getInitialState: function () {
    return {
      color: this.props.color || '#000000',
      colors: this.props.colors,
      name: this.props.name,
      label: '',
      show_swatch: false
    };
  },

  onClickHandler: function (e) {
    if (this.getDOMNode().contains(e.target)) {
      this.setState({'show_swatch': true})
    } else {
      this.setState({'show_swatch': false})
    }
  },

  componentWillMount: function () {
    document.addEventListener('click', this.onClickHandler, false);
  },

  componentWillUnmount: function () {
    document.removeEventListener('click', this.onClickHandler, false);
  },

  onSelectHandler: function (color, e) {
    this.setState({'show_swatch': false, 'color': color});
    e.preventDefault();
  },

  getColorSwatch: function () {
    var grouped_colors = _.values(_.groupBy(this.state.colors, function (x, i) {
      return Math.floor(i / 10);
    }));
    return (<div style={{
      position: 'absolute',
      border: '1px solid #ccc',
      maxHeight: 200,
      overflowX: 'hidden',
      overflowY: 'auto'
    }}>
      <table bgcolor='#EEE'
             style={{
               borderSpacing: '3px 3px',
               marginBottom: 0,
               backgroundColor: '#fff',
               borderCollapse: 'separate'
             }}>
        {grouped_colors.map(function (group_colors, i) {
          return <tr key={i}>
            {group_colors.map(function (c, j) {
              return (<td key={j}
                          onClick={_.partial(this.onSelectHandler, c)}
                          style={{
                            backgroundColor: c,
                            width: 10,
                            cursor: 'pointer',
                            padding: 5,
                            border: '1px solid #CCC',
                            borderRadius: 3,
                            height: 10
                          }}/>)
            }, this)}
          </tr>
        }, this)}
      </table>
    </div>)
  },

  render: function () {
    var swatch_dropdown = this.state.show_swatch ? this.getColorSwatch() : undefined;

    return (
        <div className="colorpick">
          <div className="form-element text">
            <label>
              <p className="label">
                {this.props.label}
              </p>
              <p className="input">
                <input
                    type="text"
                    id={this.state.name}
                    style={_.extend({}, {backgroundColor: this.state.color}, this.props.inputStyle)}
                    className="text"
                    data-test="category-color"
                    onClick={this.onClickHandler}
                />
                <input type="hidden" name={this.state.name} value={this.state.color}/>
              </p>

            </label>
          </div>
          {swatch_dropdown}
        </div>
    );
  }
});

Common.ColorPicker = React.createClass({

  getInitialState: function () {
    return {
      color: this.props.color || '#000000',
      name: this.props.name,
      label: ''
    };
  },

  componentDidMount: function () {
    $(this.refs.input.getDOMNode()).colorPicker({opacity: false});
  },

  render: function () {

    return (
        <div className="colorpick">
          <div className="form-element text">
            <label>
              <p className="label">
                {this.props.label}
              </p>
              <p className="input">
                <input
                    type="text"
                    id={this.state.name}
                    name={this.state.name}
                    style={this.props.inputStyle}
                    className="text"
                    defaultValue={this.props.value}
                    onBlur={this.props.onChangeHandler}
                    onChange={this.props.onChangeHandler}
                    ref='input'
                />
              </p>
            </label>
          </div>
        </div>
    );
  }
});

Common.PhotoUpload = React.createClass({

  render: function () {

    var src = this.props.media_url + 'images/no-photo.png';

    var teaserStyle = _.extend({}, {
      top: '65px'
    }, this.props.teaserStyle);

    if (this.props.img) {
      src = this.props.img;
      teaserStyle = _.extend({}, this.props.teaserStyle, {
        display: 'none'
      })
    }

    // need to take img on rendering
    return (
        <div className="thumb upload" style={this.props.thumbUploadStyle}>
          <div className="add-photo-teaser" style={teaserStyle}>Add Photo</div>
          <img className="photo-img-src" src={src} style={this.props.photoImageSourceStyle}/>
          <input id={this.props.id} type="file" name="photo" style={this.props.inputImageSource} />
          <span className="photo-state"></span>
        </div>
    );
  }
});
Common.SelectedInputInline = React.createClass({
  onMouseOver: function (e) {
    $(e.currentTarget).css('background-color', '#E1E1E1');
    $(e.currentTarget).find('.close').css({'visibility': 'visible'});
  },

  onMouseOut: function (e) {
    $(e.currentTarget).css('background-color', '#F1F1F1');
    $(e.currentTarget).find('.close').css({'visibility': 'hidden'});
  },

  render: function () {
    const potentialOptions = this.props.selectedList.length > 10 ?
            this.props.inlineDisplayOptions :
            this.props.selectedList,
        inlinedDisplayData = _.map(potentialOptions, function (selectedObj, key) {

          if (!selectedObj) {
            return;
          }

          const label = selectedObj['type'] === 'grouped' ?
              selectedObj['label'] + " (" + selectedObj['total_selected'] + '/' + selectedObj['total_available'] + ")" :
              selectedObj['value'];
          return (
              <div
                  onMouseOver={this.onMouseOver}
                  onMouseOut={this.onMouseOut}
                  className="selected_option"
                  data-id={selectedObj['id']}
                  key={key}
                  data-value={selectedObj['value']}
                  data-type={selectedObj['type']}
                  style={{
                    backgroundColor: '#F1F1F1',
                    color: '#A1A1A1',
                    border: '1px solid #A1A1A1',
                    float: 'left',
                    marginLeft: 5,
                    cursor: 'pointer',
                    marginBottom: 2,
                    borderRadius: 3

                  }}
              >
                <div style={{
                  float: 'left',
                  padding: '3px 5px',
                  color: '#676767'
                }}>
                  {label}
                </div>
                <div className='close'
                     key={'close_' + key}
                     data-id={selectedObj['id']}
                     data-type={selectedObj['type']}
                     data-group={selectedObj['label']}
                     onClick={selectedObj['type'] !== 'grouped' ? this.props.onRemove : this.props.onRemoveAll}
                     style={{
                       height: '100%',
                       padding: 3,
                       visibility: 'hidden',
                       display: 'block',
                       cursor: 'pointer',
                       float: 'right'
                     }}
                >
                  x
                </div>
              </div>
          );
        }, this),
        inlinedHiddenOptions = _.map(this.props.selectedList, function (option, key) {
          return (<input
              key={key}
              type="hidden"
              disabled={this.props.disabled}
              value={option['id']}
              name={option['name'] || this.props.name}
          />);
        }, this);
    return (<div>
      {inlinedDisplayData}
      {inlinedHiddenOptions}
    </div>);
  }
});
Common.InlinedDropDown = React.createClass({
  getInitialState: function () {
    return {
      selectedList: this.props.initialList || [],
      deactive_groups: []
    };
  },

  onMouseOver: function (e) {
    $(e.currentTarget).css('background-color', '#DDDDDD');
  },

  onMouseOut: function (e) {
    $(e.currentTarget).css('background-color', '#FFFFFF');
  },

  componentDidUpdate: function (prevProps, prevState) {
    if (this.props.propogationHandler) {
      this.props.propogationHandler(this.state.selectedList);
    }
  },

  flipSingle: function (e) {
    if ($(e.currentTarget).find('input[type=checkbox]').is(':checked')) {
      this.onRemove(e);
    } else {
      this.onAdd(e);
    }
    e.nativeEvent.preventDefault();
  },

  onAdd: function (e) {
    var id = $(e.currentTarget).attr('data-id'),
        value = $(e.currentTarget).data('value'),
        item = _.find(this.state.selectedList, {'id': id});
    if (!item) {
      var selectedList = this.state.selectedList,
          selectedItem = _.find(this.props.flattenedOptions, {'id': id});
      if (this.props.limit && (_.size(selectedList) == this.props.limit)) {
        selectedList.pop();
      }
      if (selectedItem) {
        selectedList.push(selectedItem);
        this.setState({selectedList: selectedList});
      }
    }
  },

  addOrRemoveAll: function (isChecked, e) {
    var group = $(e.currentTarget).attr('data-group'),
        selectedItems = _.where(this.props.flattenedOptions, {'group': group}),
        selectedIds = _.pluck(selectedItems, 'id'),
        existingGroupIds = _.pluck(_.where(this.state.selectedList, {'group': group}), 'id'),
        newItems = _.filter(this.props.flattenedOptions, function (option) {
          return _.contains(selectedIds, option['id']);
        }),
        existingListSansCurrentGroup = _.filter(this.state.selectedList, function (option) {
          return !_.contains(existingGroupIds, option['id']);
        });
    if (!isChecked) {
      this.setState({selectedList: existingListSansCurrentGroup.concat(newItems)});
    } else {
      this.setState({selectedList: existingListSansCurrentGroup});
    }
  },

  onRemoveAll: function (e) {
    var group = $(e.currentTarget).attr('data-group'),
        existingGroupIds = _.pluck(_.where(this.state.selectedList, {'group': group}), 'id');
    if (existingGroupIds) {
      var selectedList = _.filter(this.state.selectedList, function (option) {
        return !_.contains(existingGroupIds, option['id']);
      });
      this.setState({selectedList: selectedList});
    }
    e.stopPropagation();
  },

  onRemove: function (e) {
    var id = $(e.currentTarget).attr('data-id'),
        selectedItem = _.find(this.state.selectedList, {'id': id});
    if (selectedItem) {
      var selectedList = this.state.selectedList,
          index = _.indexOf(selectedList, selectedItem);
      selectedList.splice(index, 1);
      this.setState({selectedList: selectedList});
    }
    e.stopPropagation();
  },

  onClick: function () {
    if (!this.props.disabled) {
      $(this.getDOMNode()).find('.utildrop').css('display', 'block');
    }
  },

  generateOption: function (item, key, isChecked, type) {
    return (<div key={key}
                 style={{
                   cursor: 'pointer',
                   padding: 5,
                   fontFamily: 'Roboto',
                   fontSize: 14,
                   color: isChecked ? '#555555' : '#989898'
                 }}
                 onClick={type == 'All' ? _.partial(this.addOrRemoveAll, isChecked) : this.flipSingle}
                 onMouseOver={this.onMouseOver}
                 onMouseOut={this.onMouseOut}
                 data-value={item['value']}
                 data-type={item['type']}
                 data-group={item['group']}
                 data-id={item['id']}
        >

          <modules.formElements.create
              key={'checkbox_' + key}
              type="checkbox"
              data-value={item['value']}
              data-type={item['type']}
              data-id={item['id']}
              checked={isChecked}
              inputCss={{width: 0, padding: 2, margin: 3, height: 0}}
              preInputStyle={{height: 9, width: 9, backgroundSize: 8}}
              style={{clear: 'both'}}
              labelStyle={{margin: 0}}
          />
          {item['value']}
        </div>
    );
  },

  onExpansionClickHandler: function (e) {
    var selectedGroup = ($(e.currentTarget).attr('data-group')),
        deactiveGroups = this.state.deactive_groups,
        index = _.indexOf(deactiveGroups, selectedGroup);
    if (index > -1) {
      deactiveGroups.splice(index, 1)
    } else {
      deactiveGroups.push(selectedGroup);
    }
    this.setState({'deactive_groups': deactiveGroups});
  },

  generateGroupedOptions: function (selectedIds, selectedGroupIds, groupedOptionObj) {
    var listOptions = groupedOptionObj.options.map(function (item, key) {
          return this.generateOption(item, key, _.contains(selectedIds, item['id']));
        }, this),
        shouldShow = !_.contains(this.state.deactive_groups, groupedOptionObj.label),
        parentHeader = groupedOptionObj.label + ' (' + selectedGroupIds.length + '/' + groupedOptionObj.options.length + ')',
        allOptionLabel = 'All ' + groupedOptionObj.label + ' (' + groupedOptionObj.options.length + ')';

    return (<div key={groupedOptionObj.label}
                 style={{borderTop: '1px solid #F1F1F1'}}>
      <div>
        <div onClick={this.onExpansionClickHandler}
             data-group={groupedOptionObj.label}
             style={{
               cursor: 'pointer',
               fontFamily: 'Roboto',
               textTransform: 'uppercase',
               padding: '5px 8px',
               fontSize: 10
             }}>
                             <span style={{color: '#50ABA6'}}>
                                 {shouldShow ? String.fromCharCode(8212) + ' ' : '+ '}
                             </span>
          <span style={{color: '#C1C1C1'}}>
                            {parentHeader}
                            </span>
        </div>
        {this.generateOption(
            {
              type: 'All',
              id: 'All',
              group: groupedOptionObj.label,
              value: allOptionLabel
            }, 'All',
            selectedGroupIds.length == groupedOptionObj.options.length, 'All')}
      </div>
      {shouldShow ? listOptions : null}
    </div>);
  },

  render: function () {
    var selectedIds = _.pluck(this.state.selectedList, 'id');
    var renderOptions = this.props.options.map(function (item, key) {
      if (item.type == 'grouped') {
        var selectedGroupIds = _.pluck(_.where(this.state.selectedList, {'group': item['label']}), 'id');
        return this.generateGroupedOptions(selectedIds, selectedGroupIds, item);
      } else {
        return this.generateOption(item, key, _.contains(selectedIds, item['id']));
      }
    }, this);
    inlineDisplayOptions = _.filter(_.map(this.props.options, function (item, key) {
      if (item.type == 'grouped') {
        var numSelectedOptions = _.size(_.where(this.state.selectedList, {'group': item['label']}));
        if (numSelectedOptions > 0) {
          return _.extend({}, item,
              {
                'total_selected': numSelectedOptions,
                'total_available': _.size(item.options)
              }
          );
        } else {
          return undefined;
        }
      } else {
        return _.contains(selectedIds, item['id']) ? item : undefined;
      }
    }, this));
    return (
        <div
            data-label={this.props.label} key={this.props.label} className="inline">
          <div className="inline">
            <div onClick={this.onClick}
                 style={{
                   float: 'left',
                   clear: 'both',
                   width: 290,
                   backgroundColor: this.props.disabled ? '#BBB' : '#FFF',
                   minHeight: 20,
                   padding: 5,
                   marginTop: 10,
                   border: '1px solid #CACACA'
                 }}>
              <Common.SelectedInputInline
                  disabled={this.props.disabled}
                  name={this.props.name}
                  inlineDisplayOptions={inlineDisplayOptions}
                  selectedList={this.state.selectedList}
                  onRemove={this.onRemove}
                  onRemoveAll={this.onRemoveAll}
              />
            </div>
            <div className='utildrop'
                 style={{
                   position: 'initial',
                   clear: 'both',
                   display: 'none',
                   width: 300,
                   border: '1px solid #CACACA',
                   color: '#989898',
                   float: 'left',
                   maxHeight: 250,
                   overflowY: 'scroll'
                 }}>
              {renderOptions}
            </div>
          </div>
        </div>
    );
  }
});

Common.getAvailableTimes = function (startTime, endTime) {
  var interval_minutes = Pmp.Manager.Global._allowable_eta_interval || 15,
      interval_multiplier = interval_minutes / 15;

  var allTimes = _.filter(_.flatten(_.times(24, function (hour) {
    return _.times(4, function (fraction) {
      return modules.weekViewManager.getDisplayInterval((hour + 6) % 24, fraction);
    });
  })), function (v, k) {
    return k % interval_multiplier == 0;
  });

  var startIndex = startTime ? _.indexOf(allTimes, startTime) : 0,
      endIndex = endTime ? _.indexOf(allTimes, endTime) : _.size(allTimes),
      applicableTimes = allTimes.slice(startIndex, endIndex);

  if (!Pmp.Manager.Global._is_military_time) {
    return _.object(applicableTimes, applicableTimes);
  }
  var applicableInternationalTimes = _.map(applicableTimes, function (time) {
    return Pmp.Utils.toMilitaryTime(time);
  });
  return _.object(applicableInternationalTimes, applicableInternationalTimes);
};
Common.SpecificTime = React.createClass({
  render: function (props) {
    var options = _.map(Common.getAvailableTimes(this.props.start_time), function (opt) {
          return {
            id: opt,
            value: opt,
            name: 'specific_times'
          }
        }),
        selectedOptions = _.isEmpty(this.props.specific_times_display) ? [] :
            _.map(this.props.specific_times_display, function (t) {
                  return _.findWhere(options, {id: t});
                }
            );
    return (
        <Common.InlinedDropDown
            label='specific-time'
            disabled={this.props.disabled}
            initialList={selectedOptions}
            flattenedOptions={options}
            options={options}
            key="specific_times"
            name="specific_times"
        />
    );
  }
});


